<template>
	<v-dialog
		v-model="show"
		width="500"
		@keydown.esc="cancel"
		persistent
	>
		<v-card>
			<v-card-title>
				New System Message Rule
				<v-spacer />
				<Button icon text @click="cancel">
					<v-icon>mdi-close</v-icon>
				</Button>
			</v-card-title>

			<v-divider />

			<ValidationObserver
				ref="rule"
				@submit.prevent="create"
			>
				<form autocomplete="off">
					<v-card-text class="pa-6 pt-0">
						<v-select
							v-model="type"
							:items="types"
							item-text="text"
							item-value="value"
							return-object
							label="Message type"
						/>

						<v-row v-if="[1,2].includes(type.value)">
							<v-col>
								<ValidationProvider
									rules="required"
									name="Template"
									v-slot="{ errors }"
								>
									<v-select
										v-model="template"
										:items="templates"
										item-value="value"
										:error-messages="errors"
										item-text="text"
										return-object
										label="Template"
									/>
								</ValidationProvider>
							</v-col>
						</v-row>

						<template v-if="[1,2].includes(type.value) && template">
							<v-row v-for="(param,i) in template.params" :key="i">
								<v-col v-if="!param.hidden">
									<VTextFieldValidation
										v-if="typeof param.value === `number`"
										rules="required"
										v-model.number="template.params[i].value"
										:label="param.text"
										type="number"
									/>
									<VTextFieldValidation
										v-else
										rules="required"
										v-model="template.params[i].value"
										:label="param.text"
										type="string"
									/>
								</v-col>
							</v-row>
						</template>


						<v-row>
							<v-col>
								<VImage ref="image" allow-gif :mode="imageMode" />
							</v-col>
						</v-row>

						<v-row v-if="type.value === 2">
							<v-col>
								<VTextFieldValidation
									rules="required"
									v-model="title"
									label="Title"
								/>
							</v-col>
						</v-row>

						<v-row>
							<v-col>
								<VTextFieldValidation
									rules="required|max:30"
									v-model="header"
									label="Header"
								/>
							</v-col>
						</v-row>

						<v-row>
							<v-col>
								<VTextareaValidation
									rules="required|max:110"
									v-model="body_text"
									label="Text"
									rows="1"
								/>
							</v-col>
						</v-row>

						<v-row v-if="false">
							<v-col>
								<v-switch
									v-model="cta"
									label="CTA button"
									hide-details="auto"
								/>
							</v-col>
						</v-row>

						<v-row v-if="cta">
							<v-col>
								<VTextFieldValidation
									v-model="expired_at"
									label="Expires in X days"
								/>
							</v-col>
						</v-row>

						<v-row v-if="cta">
							<v-col>
								<VTextFieldValidation
									v-model="cta_button"
									rules="required|max:15"
									label="Button Text"
								/>
							</v-col>
						</v-row>

						<v-row v-if="cta">
							<v-col>
								<VTextFieldValidation
									v-model="cta_link"
									label="Button url"
								/>
							</v-col>
						</v-row>

						<v-row>
							<v-col class="pb-0">
								<v-spacer />
								<Button
									type="submit"
									:loading="loading"
								>
									Create
								</Button>
							</v-col>
						</v-row>
					</v-card-text>
				</form>
			</ValidationObserver>
		</v-card>
	</v-dialog>
</template>

<script>
import VTextFieldValidation from '@/components/validation/VTextField'
import VTextareaValidation from '@/components/validation/VTextarea'
import VImage from '@/components/material/Image'

export default {
	components: {
		VTextFieldValidation,
		VTextareaValidation,
		VImage,
	},
	computed: {
		form() {
			let data = {
				image_url: this.image_url,
				title: this.title,
				header: this.header,
				body_text: this.body_text,
				expired_at: this.expired_at,
				close_on_cta: this.close_on_cta,
				cta_button: this.cta_button,
				cta_link: this.cta_link,
				template_id: this.template.id,
				template_params: {},
			}

			for (const [key, value] of Object.entries(this.template.params)) {
				data.template_params[key] = value.value === `0` ? 0 : value.value
			}

			if (!this.cta) {
				delete data.cta_button
				delete data.cta_link
				data.close_on_cta = true
			}

			if (!this.cta_link) {
				delete data.cta_link
			}

			return data
		},
		imageMode() {
			if (this.type && this.type.value === 1) return `messageCard`
			if (this.type && this.type.value === 2) return `messageModal`
			return ``
		},
	},
	data() {
		return {
			show: false,
			loading: false,
			cta: true,

			title: null,
			header: null,
			body_text: null,
			close_on_cta: true,
			image_url: null,
			cta_button: null,
			cta_link: null,
			expired_at: 7,

			type: {},
			types: [
				{ value: 1, text: `Feed` },
				{ value: 2, text: `Modal` },
			],
			template: null,
			templates: [
				{
					id: 0,
					text: `Triggered days since account created`,
					params: {
						days_since_account_created: {
							value: 10,
							text: `Days since account created`,
						},
					}
				},
				{
					id: 1,
					text: `Triggered days since account created and # agrees`,
					params: {
						days_since_account_created: {
							value: 10,
							text: `Days since account created`,
						},
						operator: {
							value: `lte`,
							text: `Agrees`
						},
						condition_value: {
							value: 10,
							text: `Value`
						},
					}
				},
				{
					id: 2,
					text: `Triggered days since account created and # agrees`,
					params: {
						days_since_account_created: {
							value: 10,
							text: `Days since account created`,
						},
						operator: {
							value: `lte`,
							text: `Shares`
						},
						condition_value: {
							value: 10,
							text: `Value`
						},
					}
				},
			],
		}
	},
	name: `NewSystemMessageRule`,
	methods: {
		open() {
			this.reset()
			this.show = true
			return new Promise((resolve, reject) => {
				this.resolve = resolve
				this.reject = reject
			})
		},
		close() {
			this.reset()
			this.$refs.image.reset()
		},
		accept() {
			this.resolve()
			this.close()
		},
		cancel() {
			this.reject
			this.close()
		},
		create() {
			this.$refs.rule.validate().then(valid => {
				if (!valid || !this.$refs.image.exists()) return
				this.loading = true

				this.$refs.image.uploadImage()
					.then(res => {
						this.form.image_url = res.result.thumbnails.original.url
						this.form.expired_at = this.dayjs(this.expired_at).valueOf()/1000
						this.$httpInt.post(`/v2/app/messages/rules`, this.form)
							.then(() => this.close())
							.finally(() => this.loading = false)
					})
			})
		},
		reset() {
			Object.assign(this.$data, this.$options.data.apply(this))
		},
	},
}
</script>