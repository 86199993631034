<template>
	<v-container
			fluid
	>
		<NewSystemMessageRule ref="newSystemMessageRule" />
		<v-card-title>
			<v-spacer />
			<Button
				@click="newRule"
			>
				<v-icon>mdi-plus</v-icon>
			</Button>
		</v-card-title>

		<v-row justify="center">
			<v-col cols="12">
				<v-card>
					<v-card-text>
						<v-data-table
								:loading="loading"
								:headers="headers"
								:items="rules"
								item-key="id"
								class="row-pointer"
								hide-default-footer
								@click:row="goToContent"
								:footer-props="{'items-per-page-options':[10,25,50]}"
						>
							<template v-slot:[`item.template_params`]="{ item }">
								{{item.template_params}}
							</template>
							<template v-slot:[`item.actions`]="{ item }">
								<v-btn icon small @click="removeRule(item)">
									<svg-icon color="red" icon="cross-circle" />
								</v-btn>
							</template>
							<template v-slot:[`item.image`]="{ item }">
								<v-img :src="item.image_url" max-height="64" max-width="64" class="ma-2" />
							</template>
						</v-data-table>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import { globalMixIn } from '@/App'
	import NewSystemMessageRule from "@/components/dialogs/NewSystemMessageRule"

	export default {
		components: {
			NewSystemMessageRule: NewSystemMessageRule,
		},
		data: () => ({
			loading: false,
			headers: [
				{ text: `Image`, value: `image` },
				{ text: `Type`, value: `template_id.name` },
				{ text: `Title`, value: `title` },
				//{ text: 'Message', value: 'entity.text' },
				{ text: `Header`, value: `header` },
				{ text: `Body text`, value: `body_text` },
				{ text: `Params`, value: `template_params` },
				{ text: ``, value: `actions` },
			],
			rules: [],
		}),
		methods: {
			goToContent(value) {
				if (value.entity_type === `comment`) this.$router.push({name: `Post`, params: {id: value.entity.post_id}, query: {comment: value.entity.id}})
				if (value.entity_type === `post`) this.$router.push({name: `Post`, params: {id: value.entity.id}})
			},
			refresh () {
				this.loading = true
				this.$httpInt.get(`/v2/app/messages/rules`)
					.then(res => this.rules = res.data.result)
					.finally(() => this.loading = false)
			},
			newRule() {
				this.$refs.newSystemMessageRule.open()
			},
			removeRule(item) {
				this.$root.$confirm(`Delete`, `Are you sure you want to remove this rule?`, { color: `error` })
					.then((confirm) => {
						if (confirm) {
							this.$httpInt.delete(`/v2/app/messages/rules/${item._id}`)
								.then(() => {
									this.$root.$snackbar(`Rule deleted`)
									this.refresh()
								})
						}
					})
			},
		},
		mixins: [globalMixIn],
		mounted () {
			this.refresh()
		},
		name: `MessagesRules`,
		watch: {
		},
	}
</script>
